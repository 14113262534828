<template>
  <div v-if="roles && regions && phases" class="text-left">
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
      <div class="page-header">
        <h4 id="top">New User <small>{{ user.first_name }} {{ user.last_name }}</small></h4>
      </div>
    </div>

    <!-- Error Alert -->
    <danger-alert :errors="errors"></danger-alert>

    <div class="form-group">
      <label for="user_first_name">First Name</label>
      <input type="text" class="form-control" id="user_first_name" v-model="user.first_name">
    </div>

    <div class="form-group">
      <label for="user_last_name">Last Name</label>
      <input type="text" class="form-control" id="user_last_name" v-model="user.last_name">
    </div>

    <div class="form-group">
      <label for="user_email">Email</label>
      <input type="text" class="form-control" id="user_email" v-model="user.email" placeholder="email_address@example.com">
    </div>

    <div class="form-group">
      <label for="user_role">Role</label>
      <select class="form-control" id="user_role" v-model="user.role">
        <option value="Admin" selected="selected">Admin</option>
        <option value="Sales Coach">Sales Coach</option>
        <option value="Service Coach">Service Coach</option>
        <option value="Trainee">Trainee</option>
      </select>
    </div>

    <div class="form-group">
      <label for="trainee_role">Trainee Role</label>
      <select class="form-control" id="trainee_role" v-model="user.trainee_role_id">
        <option v-for="(traineeRole, index) in roles" v-bind:key="index" :value="traineeRole.id">{{ traineeRole.title }}</option>
      </select>
    </div>

    <div class="form-group">
      <label for="phase">Phase</label>
      <select class="form-control" id="phase" v-model="user.phase_id">
        <option v-for="(phase, index) in phases" v-bind:key="index" :value="phase.id">{{ phase.title }}</option>
      </select>
    </div>

    <div class="form-group">
      <label for="region">Region</label>
      <select class="form-control" id="region" v-model="user.region_id">
        <option v-for="(region, index) in regions" v-bind:key="index" :value="region.id">{{ region.name }}</option>
      </select>
    </div>

    <div class="form-group">
      <label for="user_password">Password</label>
      <input type="password" class="form-control" id="user_password" v-model="user.password">
    </div>

    <div class="form-group">
      <label for="user_password_confirmation">Password Confirmation</label>
      <input type="password" class="form-control" id="user_password_confirmation" v-model="user.password_confirmation">
    </div>

    <div class="footer">
      <div class="btn-group float-right">
        <router-link :to="{ name: 'UserIndex' }" tag="button" class="btn btn-secondary" type="button">Cancel</router-link>
        <button v-on:click="createUser" type="button" class="btn btn-success">Create User</button>
      </div>
    </div>

    <br>

  </div>
  <loading-overlay v-else />
</template>

<script>
import { UserService } from '@/common/services/user.service';
import DangerAlert from '@/common/components/danger-alert.vue';
import { TraineeRoleService } from '@/common/services/trainee_role.service';
import { PhaseService } from '@/common/services/phase.service';
import { RegionService } from '@/common/services/region.service';
import { FilterService } from '@/common/services/filter.service';
import LoadingOverlay from '@/common/components/loading-overlay.vue';

export default {
  name: 'UserNew',
  components: {
    'danger-alert': DangerAlert,
    LoadingOverlay,
  },
  data() {
    return {
      user: {
        first_name: '',
        last_name: '',
        email: '',
        role: 'Admin',
        password: '',
        password_confirmation: '',
      },
      roles: null,
      regions: null,
      phases: null,
      errors: [],
    };
  },
  created() {
    this.filters = FilterService.defaultData();
    const params = FilterService.build({ page: 1, per_page: 500 }, this.filters);
    this.getData(params);
  },
  methods: {
    createUser() {
      UserService.create(this.user)
        .then(({ data }) => {
          this.$router.push({ name: 'UserShow', params: { id: data.id } }, () => {
            this.$notify({
              title: 'Created User',
              text: `Successfully created ${data.first_name} ${data.last_name}!`,
              type: 'success',
              width: 350,
            });
          });
        })
        .catch((err) => { this.errors = err.response.data.error; });
    },
    getData(params) {
      return this.getTraineeRoles(params)
        .then((response) => {
          this.roles = response.data;
          return this.getPhases(params);
        })
        .then((response) => {
          this.phases = response.data;
          return this.getRegions(params);
        })
        .then((response) => {
          this.regions = response.data;
        });
    },
    getTraineeRoles(params) {
      return TraineeRoleService.query(params);
    },
    getPhases(params) {
      return PhaseService.query(params);
    },
    getRegions(params) {
      return RegionService.query(params);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../styles/views/users";
</style>
